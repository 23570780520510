import ReactGA from "react-ga4";

const SocialMediaLink = ({ children, ariaLabel, href }) => {
  const trackLinkClick = () => {
    // Track a link click event:
    ReactGA.event({
      category: "Social Media Link",
      action: `Social media link clicked: ${href}`,
    });
  };

  return (
    <a
      aria-label={ariaLabel}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackLinkClick}
      className="hover:text-gray-500"
    >
      {children}
    </a>
  );
};
export default SocialMediaLink;
