export function disableCookies() {
  Object.defineProperty(document, "cookie", {
    get: () => "",
    set: () => {},
    configurable: true,
  });
}

export function enableCookies() {
  Object.defineProperty(document, "cookie", {
    writable: true,
    configurable: true,
  });
}

export function clearCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  }
}

export function disableStorage() {
  const noOp = () => {};
  const storageProxy = new Proxy(
    {},
    {
      get: () => noOp,
      set: () => true,
    }
  );

  Object.defineProperty(window, "localStorage", {
    value: storageProxy,
    configurable: true,
    writable: true,
  });

  Object.defineProperty(window, "sessionStorage", {
    value: storageProxy,
    configurable: true,
    writable: true,
  });
}

export function enableStorage() {
  Object.defineProperty(window, "localStorage", {
    value: window.localStorage,
    configurable: true,
    writable: true,
  });

  Object.defineProperty(window, "sessionStorage", {
    value: window.sessionStorage,
    configurable: true,
    writable: true,
  });
}

export function clearStorage() {
  localStorage.clear();
  sessionStorage.clear();
}
