import React, { useContext } from "react";
import { useIntl } from "react-intl";
import ThemeContext from "../context/ThemeContext";

const Footer = () => {
  const intl = useIntl();
  const { theme } = useContext(ThemeContext);
  const currentYear = new Date().getFullYear(); // Auto changing to current year.

  // Text:
  const companyName = intl.formatMessage({
    id: "footer.companyName",
    defaultMessage: "Bondi Sugih Rahayu Ltd.",
  });

  return (
    <footer
      className={`${
        theme === "light" ? "bg-gray-200 text-black" : "bg-gray-900 text-white"
      } p-6`}
    >
      <p className="text-center">
        &copy; {currentYear} {companyName}
      </p>
    </footer>
  );
};
export default Footer;
