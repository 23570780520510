import ReactGA from "react-ga4";

const EmailLink = ({ children, email }) => {
  let locale = document.documentElement.lang;

  // Force locale to "en" if it is not "en" or "id":
  if (locale !== "en" && locale !== "id") {
    locale = "en";
  }

  // Determine aria-label based on locale:
  let ariaLabel = "";
  if (locale === "id") {
    ariaLabel = "Tautan Email";
  } else {
    ariaLabel = "Email Link";
  }

  const trackLinkClick = () => {
    // Track a link click event:
    ReactGA.event({
      category: "Email Link",
      action: `Email link clicked`,
    });
  };

  return (
    <a
      aria-label={ariaLabel}
      href={`mailto:${email}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackLinkClick}
      className="hover:text-gray-500"
    >
      {children}
    </a>
  );
};
export default EmailLink;
