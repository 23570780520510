import React, { useContext } from "react";
import LocaleContext from "../context/LocaleContext";
import { FlagIcon } from "react-flag-kit";

const LanguageSwitcher = () => {
  const { locale, localeSwitcher } = useContext(LocaleContext);

  const toggleLocale = () => {
    const newLocale = locale === "en" ? "id" : "en"; // Alter the locale.
    localeSwitcher(newLocale);
    // // Redirect the URL path when the locale changed:
    const currentPath = window.location.pathname.split("/").slice(2).join("/");
    window.location.pathname = `/${newLocale}/${currentPath}`;
  };

  return (
    <button
      aria-label={locale === "en" ? "Languange" : "Bahasa"}
      type="button"
      onClick={toggleLocale}
      style={{ display: "flex", alignItems: "center", gap: "8px" }}
    >
      {locale === "en" ? (
        <FlagIcon className="pointer-events-none" code="GB" size={34} />
      ) : (
        <FlagIcon className="pointer-events-none" code="ID" size={34} />
      )}
    </button>
  );
};

export default LanguageSwitcher;
