import { useState, useEffect } from "react";

const useFetchData = () => {
  const [jsonData, setJsonData] = useState(() => {
    const cachedData = localStorage.getItem("data");
    const cachedExpiration = localStorage.getItem("dataExpiration");

    if (cachedData && cachedExpiration) {
      const now = new Date().getTime();
      if (now < cachedExpiration) {
        return JSON.parse(cachedData);
      } else {
        localStorage.removeItem("data");
        localStorage.removeItem("dataExpiration");
      }
    }
    return null;
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!jsonData) {
        try {
          const response = await fetch(
            `${process.env.PUBLIC_URL}/json/data.json`
          );
          const data = await response.json();

          const now = new Date().getTime();
          const expirationTime = now + 8 * 60 * 60 * 1000; // 8 hours from now.

          localStorage.setItem("data", JSON.stringify(data));
          localStorage.setItem("dataExpiration", expirationTime);
          setJsonData(data);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };

    fetchData();
  }, [jsonData]);

  return jsonData;
};

export default useFetchData;
