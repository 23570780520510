import React from "react";
import ReactGA from "react-ga4";
import { FaTelegramPlane } from "react-icons/fa";

const TelegramButton = ({ data }) => {
  let locale = document.documentElement.lang;

  // Force locale to "en" if it is not "en" or "id":
  if (locale !== "en" && locale !== "id") {
    locale = "en";
  }

  // Determine aria-label based on locale:
  let ariaLabel = "";
  if (locale === "id") {
    ariaLabel = "Tombol Chat Telegram";
  } else {
    ariaLabel = "Telegram Chat Button";
  }

  if (!data) {
    return null;
  }

  const openTelegram = () => {
    ReactGA.event({
      category: "Telegram Chat Button",
      action: `Telegram chat button was clicked`,
    });
    window.open(
      `https://t.me/${data.messagingPlatforms.telegramNumber1}`,
      "_blank"
    );
  };

  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className="fixed bottom-[90px] right-[20px] bg-blue-500 text-white w-16 h-16 rounded-full flex justify-center items-center shadow-lg cursor-pointer transition-colors duration-300 hover:bg-blue-600"
      onClick={openTelegram}
    >
      <FaTelegramPlane size={32} />
    </button>
  );
};

export default TelegramButton;
