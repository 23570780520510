import ReactGA from "react-ga4";

const QuickLink = ({ children, href }) => {
  const trackLinkClick = () => {
    // Track a link click event:
    ReactGA.event({
      category: "Quick Link",
      action: `Quick link clicked: ${href}`,
    });
  };

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackLinkClick}
      className="hover:text-gray-500"
    >
      {children}
    </a>
  );
};
export default QuickLink;
