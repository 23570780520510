import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { useIntl } from "react-intl";
import ThemeContext from "../context/ThemeContext";
import logoDarkMode from "../assets/images/bondiqu-logo-dark-mode.png";
import logoLightMode from "../assets/images/bondiqu-logo-light-mode.png";
import LanguageSwitcher from "./LanguageSwitcher";
import ThemeSwitcher from "./ThemeSwitcher";

const Navbar = ({ currentLocale, onLocaleChange }) => {
  const location = useLocation();
  const intl = useIntl();
  const { theme } = useContext(ThemeContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const homePath = `/${currentLocale}/`;
  const aboutUsPath = `/${currentLocale}/about-us`;
  const contactUsPath = `/${currentLocale}/contact-us`;

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [theme, location, currentLocale]);

  const home = intl.formatMessage({
    id: "navbar.home",
    defaultMessage: "Home",
  });

  const aboutUs = intl.formatMessage({
    id: "navbar.aboutUs",
    defaultMessage: " ",
  });

  const contactUs = intl.formatMessage({
    id: "navbar.contactUs",
    defaultMessage: " ",
  });

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav
      className={`${
        theme === "light" ? "bg-gray-100 text-black" : "bg-gray-800 text-white"
      }`}
    >
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-20 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              id="mobile-dropdown-button"
              className={`relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition-transform duration-300 transform ${
                isMobileMenuOpen ? "rotate-90" : ""
              }`}
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleMobileMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Toggle menu</span>
              {/* Conditional rendering based on isMobileMenuOpen state */}
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d={
                    isMobileMenuOpen
                      ? "M6 18L18 6M6 6l12 12" // Close icon.
                      : "M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" // Menu icon.
                  }
                />
              </svg>
            </button>
          </div>

          <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
            {/* Logo */}
            <Link
              aria-label={home}
              className="flex flex-shrink-0 items-center"
              to={homePath}
              onClick={handleLinkClick}
            >
              <img
                className="pointer-events-none max-h-10 w-auto"
                src={theme === "light" ? logoLightMode : logoDarkMode}
                alt="logo"
              />
            </Link>

            {/* Desktop Menu */}
            <div className="hidden md:ml-6 md:block">
              <div className="flex space-x-2">
                <Link
                  to={aboutUsPath}
                  onClick={handleLinkClick}
                  className={`${
                    location.pathname === aboutUsPath && aboutUs !== " "
                      ? "bg-gray-900 text-white"
                      : ""
                  } hover:bg-gray-900 hover:text-white rounded-md p-3`}
                >
                  {aboutUs}
                </Link>
                <Link
                  to={contactUsPath}
                  onClick={handleLinkClick}
                  className={`${
                    location.pathname === contactUsPath && contactUs !== " "
                      ? "bg-gray-900 text-white"
                      : ""
                  } hover:bg-gray-900 hover:text-white rounded-md p-3`}
                >
                  {contactUs}
                </Link>
              </div>
            </div>
          </div>

          {/* Right Side Menu */}
          <div className="hidden md:block md:ml-6">
            <div className="flex items-center space-x-2">
              <LanguageSwitcher
                currentLocale={currentLocale}
                onChange={onLocaleChange}
              />
              <ThemeSwitcher />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2 md:hidden">
            <Link
              to={aboutUsPath}
              onClick={handleLinkClick}
              className={`${
                location.pathname === aboutUsPath && aboutUs !== " "
                  ? "bg-gray-900 text-white"
                  : ""
              } hover:bg-gray-900 hover:text-white block rounded-md px-3 py-2 text-base`}
            >
              {aboutUs}
            </Link>
            <Link
              to={contactUsPath}
              onClick={handleLinkClick}
              className={`${
                location.pathname === contactUsPath && contactUs !== " "
                  ? "bg-gray-900 text-white"
                  : ""
              } hover:bg-gray-900 hover:text-white block rounded-md px-3 py-2 text-base`}
            >
              {contactUs}
            </Link>
            <div className="flex items-center px-3 space-x-2">
              <LanguageSwitcher
                currentLocale={currentLocale}
                onChange={onLocaleChange}
              />
              <ThemeSwitcher />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
