import ReactGA from "react-ga4";

const TelegramLink = ({ children, telegramNumber }) => {
  let locale = document.documentElement.lang;

  // Force locale to "en" if it is not "en" or "id":
  if (locale !== "en" && locale !== "id") {
    locale = "en";
  }

  // Determine aria-label based on locale:
  let ariaLabel = "";
  if (locale === "id") {
    ariaLabel = "Tautan Chat Telegram";
  } else {
    ariaLabel = "Telegram Chat Link";
  }

  const trackLinkClick = () => {
    // Track a link click event:
    ReactGA.event({
      category: "Telegram Link",
      action: `Telegram link clicked: ${telegramNumber}`,
    });
  };

  return (
    <a
      aria-label={ariaLabel}
      href={`https://t.me/${telegramNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackLinkClick}
      className="hover:text-gray-500"
    >
      {children}
    </a>
  );
};
export default TelegramLink;
