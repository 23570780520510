import { useState, useEffect, useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import QuickLinks from "../components/QuickLinks";

const MainLayout = ({ children, currentLocale, onLocaleChange, data }) => {
  // Loading state:
  const [isLoading, setIsLoading] = useState(true);

  // Timer:
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // After 2 seconds, mark the page as loaded.
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount.
  }, []);

  const { theme } = useContext(ThemeContext);
  const location = useLocation();

  // Paths that will apply the "min-h-screen":
  const pathsToMatch = [
    `/${currentLocale}/`,
    `/${currentLocale}/about-us`,
    `/${currentLocale}/contact-us`,
    `/${currentLocale}/terms-and-conditions`,
    `/${currentLocale}/privacy-policy`,
  ];

  return (
    <div
      className={`min-h-screen flex flex-col ${
        theme === "light" ? "bg-gray-200 text-black" : "bg-gray-900 text-white"
      }`}
    >
      <Header currentLocale={currentLocale} onLocaleChange={onLocaleChange} />
      <main
        className={`flex-grow ${
          pathsToMatch.includes(location.pathname) ? "min-h-screen" : ""
        }`}
      >
        {children}
      </main>
      {!isLoading && <QuickLinks currentLocale={currentLocale} data={data} />}
      <Footer />
    </div>
  );
};

export default MainLayout;
