import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  disableCookies,
  enableCookies,
  clearCookies,
  disableStorage,
  enableStorage,
  clearStorage,
} from "../utils/dataManagement";

const DataUsageConsent = () => {
  let locale = document.documentElement.lang;

  // Force locale to "en" if it is not "en" or "id":
  if (locale !== "en" && locale !== "id") {
    locale = "en";
  }

  const privacyPolicyPath = `/${locale}/privacy-policy`;

  const [jsonData, setJsonData] = useState(() => {
    const cachedData = localStorage.getItem(`dataUsageConsent_${locale}`);
    const cachedExpiration = localStorage.getItem(
      `dataUsageConsentExpiration_${locale}`
    );

    if (cachedData && cachedExpiration) {
      const now = new Date().getTime();
      if (now < cachedExpiration) {
        return JSON.parse(cachedData);
      } else {
        localStorage.removeItem(`dataUsageConsent_${locale}`);
        localStorage.removeItem(`dataUsageConsentExpiration_${locale}`);
      }
    }
    return null;
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!jsonData) {
        try {
          const response = await fetch(
            `${process.env.PUBLIC_URL}/json/translations/${locale}/data-usage-consent.json`
          );
          const data = await response.json();

          const now = new Date().getTime();
          const expirationTime = now + 8 * 60 * 60 * 1000; // 8 hours from now.

          localStorage.setItem(
            `dataUsageConsent_${locale}`,
            JSON.stringify(data)
          );
          localStorage.setItem(
            `dataUsageConsentExpiration_${locale}`,
            expirationTime
          );
          setJsonData(data);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };

    fetchData();
  }, [jsonData, locale]);

  const text = jsonData || [];

  const [consent, setConsent] = useState(() => {
    const savedConsent = localStorage.getItem("consent");
    return savedConsent ? JSON.parse(savedConsent) : null;
  });

  const handleAcceptAll = () => {
    const newConsent = { cookies: true, storage: true };
    setConsent(newConsent);
    localStorage.setItem("consent", JSON.stringify(newConsent));
    enableCookies();
    enableStorage();
  };

  const handleRejectAll = () => {
    const newConsent = { cookies: false, storage: false };
    setConsent(newConsent);
    localStorage.setItem("consent", JSON.stringify(newConsent));
    clearCookies();
    clearStorage();
    disableCookies();
    disableStorage();
  };

  useEffect(() => {
    if (consent) {
      consent.cookies ? enableCookies() : disableCookies();
      consent.storage ? enableStorage() : disableStorage();
    }
  }, [consent]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleRejectAll();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (consent || !jsonData) {
    return null;
  }

  return (
    <div
      role="dialog"
      aria-labelledby="data-usage-consent-title"
      aria-describedby="data-usage-consent-description"
      className="fixed bottom-0 left-0 w-full bg-gray-700 text-white p-6 shadow-lg"
      tabIndex="-1"
    >
      <h2
        id="data-usage-consent-title"
        className="text-xl font-bold text-center mb-2 text-white"
      >
        {text.title}
      </h2>
      <div
        id="data-usage-consent-description"
        className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-2 mb-2"
      >
        <p className="mb-4 text-center text-white">
          {text.description.part1}{" "}
          <Link
            to={privacyPolicyPath}
            className="text-blue-400 hover:underline"
          >
            {text.description.privacyPolicyLinkText}
          </Link>
          {text.description.part2}{" "}
          <button
            type="button"
            onClick={handleRejectAll}
            className="text-blue-400 hover:underline"
          >
            {text.description.rejectButtonText}
          </button>
          .
        </p>
      </div>
      <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-2">
        <button
          type="button"
          onClick={handleAcceptAll}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded h-12 flex-grow"
        >
          {text.acceptButtonText}
        </button>
      </div>
    </div>
  );
};
export default DataUsageConsent;
