import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaThreads, FaSquareXTwitter } from "react-icons/fa6";
import SocialMediaLink from "./SocialMediaLink";
import TelegramLink from "./TelegramLink";
import WhatsAppLink from "./WhatsAppLink";
import QuickLink from "./QuickLink";
import EmailLink from "./EmailLink";

function formatPhoneNumber(number) {
  // Remove the leading "+" and split the number into parts:
  const cleanedNumber = number.replace("+", "");
  const countryCode = cleanedNumber.slice(0, 2); // "62".
  const firstPart = cleanedNumber.slice(2, 5); // "898".
  const secondPart = cleanedNumber.slice(5, 9); // "1558".
  const thirdPart = cleanedNumber.slice(9); // "100".
  return `(+${countryCode}) ${firstPart}-${secondPart}-${thirdPart}`;
}

function formatWhatsappNumber(number) {
  // Remove the leading "+":
  const cleanedNumber = number.replace("+", "");
  return cleanedNumber;
}

const QuickLinks = ({ currentLocale, data }) => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl();

  if (!data) {
    return null;
  }

  const ourServicesAndProductsTitle = intl.formatMessage({
    id: "quickLinks.ourServicesAndProductsTitle",
    defaultMessage: "Our Services and Products",
  });

  const informationsTitle = intl.formatMessage({
    id: "quickLinks.informationsTitle",
    defaultMessage: "Informations",
  });

  const contactTitle = intl.formatMessage({
    id: "quickLinks.contactTitle",
    defaultMessage: "For Bookings and Inquiries",
  });

  const socialMediaTitle = intl.formatMessage({
    id: "quickLinks.socialMediaTitle",
    defaultMessage: "Social Media",
  });

  return (
    <div className={`${theme === "light" ? "bg-gray-100" : "bg-gray-800"}`}>
      <div className="pt-12 px-10 pb-4 md:flex md:mx-[15%] gap-[10%]">
        <div className="container mx-auto mb-6">
          {/* Our Services and Products */}
          <section>
            <h2 className="text-xl font-bold mb-4">
              {ourServicesAndProductsTitle}
            </h2>
            <ul className="grid grid-cols-1 gap-4">
              {data.quickLinks.link1 && (
                <li>
                  <QuickLink href={data.quickLinks.link1}>
                    <FormattedMessage
                      id="quickLinks.link1"
                      defaultMessage="Link 1"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link2 && (
                <li>
                  <QuickLink href={data.quickLinks.link2}>
                    <FormattedMessage
                      id="quickLinks.link2"
                      defaultMessage="Link 2"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link3 && (
                <li>
                  <QuickLink href={data.quickLinks.link3}>
                    <FormattedMessage
                      id="quickLinks.link3"
                      defaultMessage="Link 3"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link4 && (
                <li>
                  <QuickLink href={data.quickLinks.link4}>
                    <FormattedMessage
                      id="quickLinks.link4"
                      defaultMessage="Link 4"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link5 && (
                <li>
                  <QuickLink href={data.quickLinks.link5}>
                    <FormattedMessage
                      id="quickLinks.link5"
                      defaultMessage="Link 5"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link6 && (
                <li>
                  <QuickLink href={data.quickLinks.link6}>
                    <FormattedMessage
                      id="quickLinks.link6"
                      defaultMessage="Link 6"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link7 && (
                <li>
                  <QuickLink href={data.quickLinks.link7}>
                    <FormattedMessage
                      id="quickLinks.link7"
                      defaultMessage="Link 7"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link8 && (
                <li>
                  <QuickLink href={data.quickLinks.link8}>
                    <FormattedMessage
                      id="quickLinks.link8"
                      defaultMessage="Link 8"
                    />
                  </QuickLink>
                </li>
              )}
              {data.quickLinks.link9 && (
                <li>
                  <QuickLink href={data.quickLinks.link9}>
                    <FormattedMessage
                      id="quickLinks.link9"
                      defaultMessage="Link 9"
                    />
                  </QuickLink>
                </li>
              )}
            </ul>
          </section>

          {/* Informations */}
          <section>
            <h2 className="text-xl font-bold mt-6 mb-4">{informationsTitle}</h2>
            <ul className="grid grid-cols-1 gap-4 mb-4">
              <li>
                <Link
                  to={`/${currentLocale}/about-us`}
                  className="hover:text-gray-500"
                >
                  <FormattedMessage
                    id="quickLinks.aboutUs"
                    defaultMessage="About Us"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={`/${currentLocale}/contact-us`}
                  className="hover:text-gray-500"
                >
                  <FormattedMessage
                    id="quickLinks.contactUs"
                    defaultMessage="Contact Us"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={`/${currentLocale}/terms-and-conditions`}
                  className="hover:text-gray-500"
                >
                  <FormattedMessage
                    id="quickLinks.termsAndConditions"
                    defaultMessage="Terms and Conditions"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={`/${currentLocale}/privacy-policy`}
                  className="hover:text-gray-500"
                >
                  <FormattedMessage
                    id="quickLinks.privacyPolicy"
                    defaultMessage="Privacy Policy"
                  />
                </Link>
              </li>
            </ul>
          </section>
        </div>
        <div className="container mx-auto mb-6">
          {/* For Bookings and Inquiries */}
          <section>
            <h2 className="text-xl font-bold mb-4">{contactTitle}</h2>
            <ul className="grid grid-cols-1 gap-4 mb-4">
              <li>
                <p>Telegram:</p>
                {data.messagingPlatforms.telegramNumber1 && (
                  <p>
                    <TelegramLink
                      telegramNumber={data.messagingPlatforms.telegramNumber1}
                    >
                      {formatPhoneNumber(
                        data.messagingPlatforms.telegramNumber1
                      )}
                    </TelegramLink>
                  </p>
                )}
                {data.messagingPlatforms.telegramNumber2 && (
                  <p>
                    <TelegramLink
                      telegramNumber={data.messagingPlatforms.telegramNumber2}
                    >
                      {formatPhoneNumber(
                        data.messagingPlatforms.telegramNumber2
                      )}
                    </TelegramLink>
                  </p>
                )}
                {data.messagingPlatforms.telegramNumber3 && (
                  <p>
                    <TelegramLink
                      telegramNumber={data.messagingPlatforms.telegramNumber3}
                    >
                      {formatPhoneNumber(
                        data.messagingPlatforms.telegramNumber3
                      )}
                    </TelegramLink>
                  </p>
                )}
              </li>
              <li>
                <p>Whatsapp:</p>
                {data.messagingPlatforms.whatsAppNumber1 && (
                  <p>
                    <WhatsAppLink
                      whatsAppNumber={formatWhatsappNumber(
                        data.messagingPlatforms.whatsAppNumber1
                      )}
                    >
                      {formatPhoneNumber(
                        data.messagingPlatforms.whatsAppNumber1
                      )}
                    </WhatsAppLink>
                  </p>
                )}
                {data.messagingPlatforms.whatsAppNumber2 && (
                  <p>
                    <WhatsAppLink
                      whatsAppNumber={formatWhatsappNumber(
                        data.messagingPlatforms.whatsAppNumber2
                      )}
                    >
                      {formatPhoneNumber(
                        data.messagingPlatforms.whatsAppNumber2
                      )}
                    </WhatsAppLink>
                  </p>
                )}
                {data.messagingPlatforms.whatsAppNumber3 && (
                  <p>
                    <WhatsAppLink
                      whatsAppNumber={formatWhatsappNumber(
                        data.messagingPlatforms.whatsAppNumber3
                      )}
                    >
                      {formatPhoneNumber(
                        data.messagingPlatforms.whatsAppNumber3
                      )}
                    </WhatsAppLink>
                  </p>
                )}
              </li>
              <li>
                <p>Email:</p>
                <p>
                  <EmailLink email={data.email}>{data.email}</EmailLink>
                </p>
              </li>
            </ul>
          </section>

          {/* Social Media */}
          <section>
            <h2 className="text-xl font-bold mt-6 mb-4">{socialMediaTitle}</h2>
            <div className="flex space-x-4 mb-4">
              {/* Facebook */}
              {data.socialMedia.facebook && (
                <SocialMediaLink
                  ariaLabel={"Facebook"}
                  href={data.socialMedia.facebook}
                >
                  <FaFacebook size={30} className="hover:text-gray-500" />
                </SocialMediaLink>
              )}

              {/* Instagram */}
              {data.socialMedia.instagram && (
                <SocialMediaLink
                  ariaLabel={"Instagram"}
                  href={data.socialMedia.instagram}
                >
                  <FaInstagram size={30} className="hover:text-gray-500" />
                </SocialMediaLink>
              )}

              {/* Threads */}
              {data.socialMedia.threads && (
                <SocialMediaLink
                  ariaLabel={"Threads"}
                  href={data.socialMedia.threads}
                >
                  <FaThreads size={30} className="hover:text-gray-500" />
                </SocialMediaLink>
              )}

              {/* Twitter/X */}
              {data.socialMedia.x && (
                <SocialMediaLink
                  ariaLabel={"Twitter/X"}
                  href={data.socialMedia.x}
                >
                  <FaSquareXTwitter size={30} className="hover:text-gray-500" />
                </SocialMediaLink>
              )}

              {/* TikTok */}
              {data.socialMedia.tiktok && (
                <SocialMediaLink
                  ariaLabel={"TikTok"}
                  href={data.socialMedia.tiktok}
                >
                  <FaTiktok size={30} className="hover:text-gray-500" />
                </SocialMediaLink>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default QuickLinks;
