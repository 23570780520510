import ReactGA from "react-ga4";

const WhatsAppLink = ({ children, whatsAppNumber, message }) => {
  let locale = document.documentElement.lang;

  // Force locale to "en" if it is not "en" or "id":
  if (locale !== "en" && locale !== "id") {
    locale = "en";
  }

  // Determine aria-label based on locale:
  let ariaLabel = "";
  if (locale === "id") {
    ariaLabel = "Tautan Chat WhatsApp";
  } else {
    ariaLabel = "WhatsApp Chat Link";
  }

  // Encode to URI format:
  const encodeMessage = (text) => {
    return encodeURIComponent(text);
  };

  // Track a link click event:
  const trackLinkClick = () => {
    ReactGA.event({
      category: "WhatsApp Link",
      action: `WhatsApp link clicked: +${whatsAppNumber}`,
    });
  };

  return (
    <a
      aria-label={ariaLabel}
      href={`https://wa.me/${whatsAppNumber}${
        message ? `?text=${encodeMessage(message)}` : ""
      }`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackLinkClick}
      className="hover:text-gray-500"
    >
      {children}
    </a>
  );
};
export default WhatsAppLink;
