import React, { useContext, useState, useEffect, Suspense, lazy } from "react";
import LocaleContext from "./context/LocaleContext";
import { initGA, logPageView } from "./utils/analytics";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import useFetchData from "./hooks/useFetchData";
import { IntlProvider } from "react-intl";
import ScrollToTop from "./components/ScrollToTop";
import MainLayout from "./layouts/MainLayout";
import TelegramButton from "./components/TelegramButton";
import WhatsAppButton from "./components/WhatsAppButton";
import DataUsageConsent from "./components/DataUsageConsent";

// Lazy-loaded components:
const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const NotFound = lazy(() => import("./pages/NotFound"));

// Cache for fetched JSON:
let cachedJsonData = { en: null, id: null };

const App = () => {
  // Google Analytics:
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  // Load language:
  const { locale, localeSwitcher } = useContext(LocaleContext);

  // Loading state:
  const [isLoading, setIsLoading] = useState(true);

  // Timer:
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // After 2 seconds, mark the page as loaded.
    }, 1500);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount.
  }, []);

  // Load JSON data:
  const [jsonData, setJsonData] = useState(cachedJsonData[locale]);

  const data = useFetchData();

  useEffect(() => {
    if (cachedJsonData[locale]) {
      setJsonData(cachedJsonData[locale]);
      return;
    }

    const fetchData = async () => {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/json/translations/${locale}.json`
      );
      const data = await response.json();
      cachedJsonData[locale] = data;
      setJsonData(data);
    };

    fetchData();
  }, [locale]);

  const text = jsonData || {};

  // Save language preference to locale storage:
  const handleLocaleChange = (newLocale) => {
    localeSwitcher(newLocale);
  };

  // A wrapper to validate the locale:
  const ValidateLocale = ({ children }) => {
    const location = useLocation();
    const pathLocale = location.pathname.split("/")[1]; // "en" or "id".

    useEffect(() => {
      if (
        (pathLocale === "en" || pathLocale === "id") &&
        pathLocale !== locale
      ) {
        localeSwitcher(pathLocale);
      }
    }, [pathLocale]);

    // If pathLocale is valid, render children:
    return children;
  };

  // Note: MainLayout prop drilling for Language Switcher (MainLayout > Header > Navbar > Language Switcher).

  // Check the saved data usage consent (if any):
  const savedConsent = localStorage.getItem("consent");

  return (
    <IntlProvider locale={locale} messages={text}>
      <Router>
        <ScrollToTop />
        <MainLayout
          currentLocale={locale}
          onLocaleChange={handleLocaleChange}
          data={data}
        >
          <Suspense fallback={null}>
            <Routes>
              {/* Redirects */}
              <Route
                path="/"
                element={<Navigate to={`/${locale}/`} replace />}
              />
              <Route
                path="/about-us"
                element={<Navigate to={`/${locale}/about-us`} replace />}
              />
              <Route
                path="/contact-us"
                element={<Navigate to={`/${locale}/contact-us`} replace />}
              />
              <Route
                path="/terms-and-conditions"
                element={
                  <Navigate to={`/${locale}/terms-and-conditions`} replace />
                }
              />
              <Route
                path="/privacy-policy"
                element={<Navigate to={`/${locale}/privacy-policy`} replace />}
              />

              {/* Routes */}
              <Route
                path="/*"
                element={
                  <ValidateLocale>
                    <Routes>
                      <Route path={`/${locale}`} element={<Home />} />
                      <Route
                        path={`/${locale}/about-us`}
                        element={<AboutUs />}
                      />
                      <Route
                        path={`/${locale}/contact-us`}
                        element={<ContactUs />}
                      />
                      <Route
                        path={`/${locale}/terms-and-conditions`}
                        element={<TermsAndConditions />}
                      />
                      <Route
                        path={`/${locale}/privacy-policy`}
                        element={<PrivacyPolicy />}
                      />
                      {!isLoading && <Route path="*" element={<NotFound />} />}
                    </Routes>
                  </ValidateLocale>
                }
              />
            </Routes>
          </Suspense>

          <TelegramButton data={data} />
          <WhatsAppButton data={data} />
          {!savedConsent && document.documentElement.lang === locale && (
            <DataUsageConsent />
          )}
        </MainLayout>
      </Router>
    </IntlProvider>
  );
};

export default App;
