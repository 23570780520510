import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { FaSun, FaMoon } from "react-icons/fa";

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  let locale = document.documentElement.lang;

  // Force locale to "en" if it is not "en" or "id":
  if (locale !== "en" && locale !== "id") {
    locale = "en";
  }

  // Determine aria-label based on locale:
  let ariaLabel = "";
  if (locale === "id") {
    ariaLabel = theme === "light" ? "Tema Terang" : "Tema Gelap";
  } else {
    ariaLabel = theme === "light" ? "Light Theme" : "Dark Theme";
  }

  return (
    <div
      className={`flex justify-end p-2 ${
        theme === "light" ? " dark:bg-gray-100" : "bg-gray-200 dark:bg-gray-800"
      } `}
    >
      <button
        aria-label={ariaLabel}
        type="button"
        onClick={toggleTheme}
        className={`p-2 rounded-full ${
          theme === "light"
            ? "dark:bg-gray-300 dark:text-gray-700 hover:bg-gray-400"
            : "dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-900"
        } `}
      >
        {theme === "dark" ? <FaMoon size={18} /> : <FaSun size={18} />}
      </button>
    </div>
  );
};
export default ThemeSwitcher;
