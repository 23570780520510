import React from "react";
import Navbar from "./Navbar";

const Header = ({ currentLocale, onLocaleChange }) => {
  return (
    <header>
      <Navbar currentLocale={currentLocale} onLocaleChange={onLocaleChange} />
    </header>
  );
};
export default Header;
