import React, { createContext, useState, useEffect } from "react";

const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(() => {
    const currentLocale =
      localStorage.getItem("locale") ||
      navigator.language.split(/[-_]/)[0] ||
      "id";
    return currentLocale === "en" || currentLocale === "id"
      ? currentLocale
      : "en";
  });

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  const localeSwitcher = (locale) => {
    setLocale(locale === "en" || locale === "id" ? locale : "en"); // Assure the locale.
    localStorage.setItem("locale", locale);
  };

  return (
    <LocaleContext.Provider value={{ locale, localeSwitcher }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;
